<template>
  <div style="background-color: #ececec; padding: 20px">
    <a-row :gutter="16">
      <a-col :span="8">
        <a-card
          hoverable="true"
          title="登山赛车3"
          :bordered="true"
          @click="handleCardClick('hillCar')"
        >
          <div class="image-container">
            <img class="appIcon" alt="imageCar" :src="imageCar" />
          </div>
          <p>
            这是一个非常好玩的登山赛车应用，几乎模拟出了汽车在不同公路上的真实驾驶感受，同时得到了一些逼真的物理效果反馈，应用可以选择多种不同难度的地图环境。
          </p>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card
          hoverable="true"
          title="一笔画"
          :bordered="true"
          @click="handleCardClick('oneLine')"
        >
          <div class="image-container">
            <img class="appIcon" alt="oneLine" :src="oneLine" />
          </div>
          <p>
            一笔画是一个简单有趣的益智应用，点击屏幕画线，数百个不同难度的关卡，丰富多样的图形场景。您可以从任何地方开始画线，
            只能画一条线连接所有点。
          </p>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card
          hoverable="true"
          title="天空跳跳球"
          :bordered="true"
          @click="handleCardClick('skyBall')"
        >
          <div class="image-container">
            <img class="appIcon" alt="skyBall" :src="skyBall" />
          </div>
          <p>
            手指左右移动小球，避开各种陷阱和障碍物，不要偏离跳板，快来玩吧，不断的挑战你的极限！五彩缤纷的场景，一键式简单又富有挑战的操作，充满趣味性！
          </p>
        </a-card>
      </a-col>
    </a-row>
    <a-row style="margin-top: 16px" :gutter="16">
      <a-col :span="8">
        <a-card
          hoverable="true"
          title="图形消除"
          :bordered="true"
          @click="handleCardClick('hexImage')"
        >
          <div class="image-container">
            <img class="appIcon" alt="hexImage" :src="hexImage" />
          </div>
          <p>
            这是一款休闲解压的拼图应用，玩家们需要在一个大的多边形棋盘当中，通过拼接各个形状的图形碎片，最后填满整个棋盘，即可完成关卡。
          </p>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card
          hoverable="true"
          title="脑点子"
          :bordered="true"
          @click="handleCardClick('braindot')"
        >
          <div class="image-container">
            <img class="appIcon" alt="braindot" :src="braindot" />
          </div>
          <p>
            想办法让橙色球和绿色球相碰，你就能过关了，用手指自由的在屏幕中画线搭桥，撬动小球滚动，发挥你的想象力是过关的关键。
          </p>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card
          hoverable="true"
          title="连线大师"
          :bordered="true"
          @click="handleCardClick('connect')"
        >
          <div class="image-container">
            <img class="appIcon" alt="connect" :src="connect" />
          </div>
          <p>
            玩法：将相同的颜色的方块，用线连接起来，连线不能交叉，并且需要通过所有的白色方块，不能留下空格。
          </p>
        </a-card>
      </a-col>
    </a-row>
    <a-row style="margin-top: 16px" :gutter="16">
      <a-col :span="8">
        <a-card
          hoverable="true"
          title="最美时钟"
          :bordered="true"
          @click="handleCardClick('clock')"
        >
          <div class="image-container">
            <img class="appIcon" alt="clock" :src="clock" />
          </div>
          <p>一款用于日常生活记录时间，以及学习备考培养专注力的必备工具。</p>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card
          hoverable="true"
          title="数字华容道"
          :bordered="true"
          @click="handleCardClick('number')"
        >
          <div class="image-container">
            <img class="appIcon" alt="number" :src="number" />
          </div>
          <p>
            数字华容道是一款益智解谜的休闲应用，手指点击或者滑动屏幕，将数字按顺序排列，即可完成应用，你可以不断的超越自己，用更少的
            时间和步数来完成应用，挑战你的反应能力和观察能力。
          </p>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card
          hoverable="true"
          title="水颜色分类"
          :bordered="true"
          @click="handleCardClick('water')"
        >
          <div class="image-container">
            <img class="appIcon" alt="water" :src="water" />
          </div>
          <p>
            11种精心制作的漂亮瓶子，你可以随意切换使用它们，玩起来超解压。瓶子里五颜六色的水将考验你的分类技术。你需要将各种颜色的液体分配到瓶子中，以便每个瓶子都充满相同颜色的水，则闯关成功。
          </p>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      imageCar: require("@/assets/img/icon-car.png"),
      oneLine: require("@/assets/img/icon-line.png"),
      skyBall: require("@/assets/img/icon-jump-ball.png"),
      hexImage: require("@/assets/img/icon-hex.png"),
      braindot: require("@/assets/img/Icon-braindot.png"),
      connect: require("@/assets/img/icon-connect.png"),
      clock: require("@/assets/img/icon-clock.png"),
      number: require("@/assets/img/Icon-number-slide.png"),
      water: require("@/assets/img/Icon-water.png"),
    };
  },
  methods: {
    handleCardClick(cardType: string) {
      let url = "";
      switch (cardType) {
        case "hillCar":
          url = "https://apps.apple.com/app/id1571024783";
          break;
        case "oneLine":
          url = "https://apps.apple.com/app/id1558841832";
          break;
        case "skyBall":
          url = "https://apps.apple.com/app/id1364514609";
          break;
        case "clock":
          url = "https://apps.apple.com/app/id1526141514";
          break;
        case "connect":
          url = "https://apps.apple.com/app/id1595443254";
          break;
        case "number":
          url = "https://apps.apple.com/app/id6462195635";
          break;
        case "water":
          url = "https://apps.apple.com/app/id1330476152";
          break;
        case "braindot":
          url = "https://apps.apple.com/app/id1545744485";
          break;
        case "hexImage":
          url = "https://apps.apple.com/app/id1601043572";
          break;
      }
      window.open(url, "_blank");
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.appIcon {
  border-radius: 10px;
}
</style>
